/* eslint-disable no-unused-vars */
// import 'virtual:windi.css'
// import './application.css'

import '@hotwired/turbo-rails'
import '@hotwired/stimulus'
import TurboPower from 'turbo_power'

TurboPower.initialize(Turbo.StreamActions)

import { Rails } from '@rails/ujs'
try { Rails.start() } catch (e) { console.log(e) }

import 'fslightbox'

import { Application } from "@hotwired/stimulus"

const application = Application.start()

application.debug = false
window.Stimulus   = application

import vue_controller from '../controllers/vue_controller'
application.register('vue', vue_controller)

import date_controller from '../controllers/date_controller'
application.register('date', date_controller)

import redirect_controller from '../controllers/redirect_controller'
application.register('redirect', redirect_controller)

import allergens_controller from '../controllers/allergens_controller'
application.register('allergens', allergens_controller)

import glide_controller from '../controllers/glide_controller'
application.register('glide', glide_controller)


document.addEventListener("turbo:before-fetch-response", function(event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    var response = event.detail.fetchResponse.response
    if (response.redirected && response.status == 303) {
      event.preventDefault()
      window.location = response.url
    }
    if (response.status == 500) {
      event.preventDefault()
      window.alert("Sorry, something went wrong, please try again")
    }
  }
})