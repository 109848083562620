import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["item"]

  show(e) {
    e.preventDefault()
    this.itemTargets.forEach((item) => {
      item.classList.remove("hidden")
    })
  }

}