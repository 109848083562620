import { Controller } from "@hotwired/stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["input"]
  static values = {
    enable: Array
  }

  connect() {
    flatpickr(this.inputTarget, {
      altInput: true,
      altFormat: "j F Y",
      dateFormat: "Y-m-d",
      minDate: "today",
      enable: this.enableValue,
      maxDate: new Date().fp_incr(7*6)
    })
  }
}